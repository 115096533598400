<template>
  <div class="z-2 h-100 position-relative" :style="slidePosition">
    <div class=" position-absolute line2Wrapper"
      style="height:0.5%;width:100%;left:0%;top:82.5%;transform:translate(0%, -50%);">
      <div class=" bg-warning line2" style="height:100%;width:0%"></div>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
/* import { normalize } from "@/utils/ranges" */
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      let response = slidePositionPlain(this.globalUnits, this.slideIndex)
      response.width = "200%"
      return response
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() { },
  methods: {
    line2Left(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".line2Wrapper",
          left: ["0%", "-20%"],
          duration: 600,
          delay: 300,
          easing: "easeInOutQuad"
        })
        .add({
          targets: ".line2Wrapper",
          left: ["-20%", "120%"],
          duration: 600,
          delay: 300,
          easing: "linear"
        })
      /* let progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress) */

      animation.seek(animation.duration * percentage)
    },
    line2Width(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".line2",
          width: ["0%", "100%"],
          duration: 600,
          delay: 600,
          easing: "easeInOutQuad"
        })
        .add({
          targets: ".line2",
          width: ["100%", "0%"],
          duration: 600,
          delay: 1200,
          easing: "linear"
        })
      /* let progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress) */
      animation.seek(animation.duration * percentage)
    }
  },
  watch: {
    innerPosition() {
      this.line2Left(this.innerPosition)
      this.line2Width(this.innerPosition)
    }
  }
}
</script>
<style></style>
